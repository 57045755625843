/******************************
******************************
******************************
        FONT FAMILY
*******************************
*******************************
*******************************/
@font-face {
  font-family: 'MarkPro';
  src: local('MarkPro-Hairline'),
  url(./assets/fonts/MarkPro-Hairline.ttf) format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'MarkPro';
  src: local('MarkPro-Thin'),
  url(./assets/fonts/MarkPro-Thin.ttf) format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'MarkPro';
  src: local('MarkPro-ExtraLight'),
  url(./assets/fonts/MarkPro-ExtraLight.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'MarkPro';
  src: local('MarkPro'),
  url(./assets/fonts/MarkPro.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'MarkPro';
  src: local('MarkPro-Book'),
  url(./assets/fonts/MarkPro-Book.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'MarkPro';
  src: local('MarkPro-Medium'),
  url(./assets/fonts/MarkPro-Medium.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'MarkPro';
  src: local('MarkPro-Bold'),
  url(./assets/fonts/MarkPro-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'MarkPro';
  src: local('MarkPro-Heavy'),
  url(./assets/fonts/MarkPro-Heavy.ttf) format('truetype');
  font-weight: 800;
}


@font-face {
  font-family: 'MarkPro';
  src: local('MarkPro-Black'),
  url(./assets/fonts/MarkPro-Black.ttf) format('truetype');
  font-weight: 900;
}


@font-face {
  font-family: 'NotoSansJP';
  src: local('NotoSansJP-Thin'),
  url(./assets/fonts/NotoSansJP/NotoSansJP-Thin.ttf) format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'NotoSansJP';
  src: local('NotoSansJP-Light'),
  url(./assets/fonts/NotoSansJP/NotoSansJP-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'NotoSansJP';
  src: local('NotoSansJP-Regular'),
  url(./assets/fonts/NotoSansJP/NotoSansJP-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'NotoSansJP';
  src: local('NotoSansJP-Medium'),
  url(./assets/fonts/NotoSansJP/NotoSansJP-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'NotoSansJP';
  src: local('NotoSansJP-Bold'),
  url(./assets/fonts/NotoSansJP/NotoSansJP-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'NotoSansJP';
  src: local('NotoSansJP-Black'),
  url(./assets/fonts/NotoSansJP/NotoSansJP-Black.ttf) format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'NotoSerifJP';
  src: local('NotoSerifJP-ExtraLight'),
  url(./assets/fonts/NotoSerifJP/NotoSerifJP-ExtraLight.ttf) format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'NotoSerifJP';
  src: local('NotoSerifJP-Light'),
  url(./assets/fonts/NotoSerifJP/NotoSerifJP-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'NotoSerifJP';
  src: local('NotoSerifJP-Regular'),
  url(./assets/fonts/NotoSerifJP/NotoSerifJP-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'NotoSerifJP';
  src: local('NotoSerifJP-Medium'),
  url(./assets/fonts/NotoSerifJP/NotoSerifJP-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'NotoSerifJP';
  src: local('NotoSerifJP-Bold'),
  url(./assets/fonts/NotoSerifJP/NotoSerifJP-Bold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'NotoSerifJP';
  src: local('NotoSerifJP-Black'),
  url(./assets/fonts/NotoSerifJP/NotoSerifJP-Black.ttf) format('truetype');
  font-weight: 900;
}


/******************************
******************************
******************************
            BASIC
*******************************
*******************************
*******************************/

* {
  font-family: MarkPro, arial, sans-serif;
}

.lang-ja * {
  font-family: NotoSansJP-Regular, serif;
}

.ff-lang-ja-text-en {
  font-family: MarkPro, arial, sans-serif;
}

.material-icons {
  font-family: 'Material Icons', serif !important;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
}

body.disable-scroll {
  position: fixed;
  overflow: hidden;
}

.landing-page-product-price-dropdown .mat-option {
  height: 3.5rem !important;
  font-size: 1.2rem !important;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #AFAFAF;
  border-radius: 10px;
}

:root {
  scrollbar-color: #AFAFAF transparent;
  scrollbar-width: 7px;
  scrollbar-height: 7px;
}

/******************************
        GLOBAL HEADER
*******************************/
.header-language-list,
.header-country-list {
  background: #000000;
  border-radius: 0 !important;
}

.header-language-list .mat-menu-content,
.header-country-list .mat-menu-content {
  padding: 0 !important;
}

.header-language-item,
.header-country-item {
  color: #FFFFFF;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4) !important;
  font-size: 1.2rem;
  font-weight: 400;
  height: 3.5rem !important;
  width: 20rem !important;
  line-height: 3.5rem !important;
}

.header-language-item.selected,
.header-country-item.selected {
  background: #1E1E21;
  color: #FFB64C;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: unset !important;
  line-height: unset !important;
}

/******************************
        DESKTOP DIALOG
*******************************/

.cdk-overlay-container, .cdk-overlay-backdrop {
  z-index: 1001 !important;
}

.mat-dialog-container {
  background: #D7D7D7;
  border-radius: 2rem !important;
  overflow: hidden !important;
  max-height: 90vh !important;
}

.desktop-dialog-backdrop {
  backdrop-filter: blur(4px);
}

.mat-menu-panel {
  min-height: unset !important;
}

.cdk-overlay-pane.dialog.w50 {
  max-width: unset !important;
  width: 50vw !important;
}

.cdk-overlay-pane.dialog.w60 {
  max-width: unset !important;
  width: 60vw !important;
}

.cdk-overlay-pane.dialog.w70 {
  max-width: unset !important;
  width: 70vw !important;
}

.cdk-overlay-pane.dialog.w80 {
  max-width: unset !important;
  width: 80vw !important;
}

.cdk-overlay-pane.brew-dialog {
  width: 69rem;
}

.brew-dialog .mat-dialog-container {
  background-color: #2c2c2c;
  border-radius: 0.75rem;
  padding: 2.5rem;
}

/******************************
        Loading Screen
*******************************/
.spinner-panel .mat-dialog-container {
  padding: 0 !important;
}

.mat-spinner circle {
  stroke: #FFB64C !important;
}

.spinner-backdrop,
.spinner-backdrop + .cdk-global-overlay-wrapper,
.spinner-panel {
  z-index: 1051 !important;
}

/******************************
      Notification Popup
*******************************/

.cdk-global-overlay-wrapper {
  z-index: 1001 !important;
}

.mat-snack-bar-container {
  border-radius: 0.75rem !important;
}

.mat-snack-bar-container .mat-simple-snackbar span {
  font-size: 1.5rem !important;
  word-break: break-all;
}

.mat-snack-bar-container.danger {
  background: rgba(255, 0, 0, 1) !important;
}

.mat-snack-bar-container.warning {
  background: rgba(253, 178, 31, 1) !important;
}

.mat-snack-bar-container.success {
  background: rgba(60, 179, 113, 1) !important;
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 1001;
}

/******************************
      Material Icon link
*******************************/
.mat-icon-link {
  line-height: 1;
}

.mat-icon-link:hover {
  cursor: pointer;
}

.mat-icon.large {
  width: 4.2rem;
  height: 4.2rem;
  font-size: 4.2rem;
}

.mat-icon.medium {
  width: 1.8rem;
  height: 1.8rem;
  font-size: 1.8rem;
}

.mat-icon.small, .mat-icon.small svg {
  width: 1.6rem;
  height: 1.6rem;
  font-size: 1.6rem;
}

/******************************
      Confirmation dialog
*******************************/

.confirm-dialog {
  max-width: 50rem;
  padding: 1rem 2rem 0 2rem;
  min-height: 17rem;
}

.confirm-dialog .heading {
  font-size: 2.5rem;
  line-height: 3.5rem;
  text-align: center;
  margin-bottom: 2.4rem;
  word-break: break-word;
}

.lang-ja :host .heading {
  font-family: NotoSerifJP-Medium, sans-serif;
}

.confirm-dialog .confirm-button {
  margin-bottom: 1rem;
}

.confirm-dialog .cancel-text {
  cursor: pointer;
  width: 100%;
  padding: 1rem;
}

/******************************
      Account form
*******************************/
.account-form .form-field-error {
  height: auto !important;
}

/******************************
******************************
******************************
        MEDIA QUERIES
*******************************
*******************************
*******************************/


/******************************
          REM BASE
*******************************/

@media (max-width: 1439px) {
  html {
    font-size: 8px;
  }
}

@media (min-width: 1440px) and (max-width: 1919px) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 1920px) and (max-width: 2559px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 2560px) and (max-width: 3439px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 3440px) {
  html {
    font-size: 20px;
  }
}
